/**
 * Toast style.
 */
export enum SnotifyStyle {
  simple = 'simple',
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info',
  async = 'async',
  confirm = 'confirm',
  prompt = 'prompt'
}
