// ! moment.js locale configuration
// ! locale : Kazakh [kk]
// ! authors : Nurlan Rakhimzhanov : https://github.com/nurlan

import { LocaleData } from '..';

const suffixes = {
  0: '-ші',
  1: '-ші',
  2: '-ші',
  3: '-ші',
  4: '-ші',
  5: '-ші',
  6: '-шы',
  7: '-ші',
  8: '-ші',
  9: '-шы',
  10: '-шы',
  20: '-шы',
  30: '-шы',
  40: '-шы',
  50: '-ші',
  60: '-шы',
  70: '-ші',
  80: '-ші',
  90: '-шы',
  100: '-ші'
};

export const kkLocale: LocaleData = {
  abbr: 'kk',
  months : 'қаңтар_ақпан_наурыз_сәуір_мамыр_маусым_шілде_тамыз_қыркүйек_қазан_қараша_желтоқсан'.split('_'),
  monthsShort : 'қаң_ақп_нау_сәу_мам_мау_шіл_там_қыр_қаз_қар_жел'.split('_'),
  weekdays : 'жексенбі_дүйсенбі_сейсенбі_сәрсенбі_бейсенбі_жұма_сенбі'.split('_'),
  weekdaysShort : 'жек_дүй_сей_сәр_бей_жұм_сен'.split('_'),
  weekdaysMin : 'жк_дй_сй_ср_бй_жм_сн'.split('_'),
  longDateFormat : {
    LT : 'HH:mm',
    LTS : 'HH:mm:ss',
    L : 'DD.MM.YYYY',
    LL : 'D MMMM YYYY',
    LLL : 'D MMMM YYYY HH:mm',
    LLLL : 'dddd, D MMMM YYYY HH:mm'
  },
  calendar : {
    sameDay : '[Бүгін сағат] LT',
    nextDay : '[Ертең сағат] LT',
    nextWeek : 'dddd [сағат] LT',
    lastDay : '[Кеше сағат] LT',
    lastWeek : '[Өткен аптаның] dddd [сағат] LT',
    sameElse : 'L'
  },
  relativeTime : {
    future : '%s ішінде',
    past : '%s бұрын',
    s : 'бірнеше секунд',
    ss : '%d секунд',
    m : 'бір минут',
    mm : '%d минут',
    h : 'бір сағат',
    hh : '%d сағат',
    d : 'бір күн',
    dd : '%d күн',
    M : 'бір ай',
    MM : '%d ай',
    y : 'бір жыл',
    yy : '%d жыл'
  },
  dayOfMonthOrdinalParse: /\d{1,2}-(ші|шы)/,
  ordinal(_num: number): string {
    const a = _num % 10;
    const b = _num >= 100 ? 100 : null;

    return _num + (suffixes[_num] || suffixes[a] || suffixes[b]);
  },
  week : {
    dow : 1, // Monday is the first day of the week.
    doy : 7  // The week that contains Jan 7th is the first week of the year.
  }
};
