import { getParsingFlags } from './parsing-flags';
import { DATE, HOUR, MILLISECOND, MINUTE, MONTH, SECOND, WEEK, WEEKDAY, YEAR } from '../units/constants';
import { daysInMonth } from '../units/month';
import { DateParsingConfig } from './parsing.types';

export function checkOverflow(config: DateParsingConfig): DateParsingConfig {
  let overflow;
  const a = config._a;

  if (a && getParsingFlags(config).overflow === -2) {
    // todo: fix this sh*t
    overflow =
      a[MONTH] < 0 || a[MONTH] > 11 ? MONTH :
        a[DATE] < 1 || a[DATE] > daysInMonth(a[YEAR], a[MONTH]) ? DATE :
          a[HOUR] < 0 || a[HOUR] > 24 || (a[HOUR] === 24 && (a[MINUTE] !== 0 || a[SECOND] !== 0 || a[MILLISECOND] !== 0)) ? HOUR :
            a[MINUTE] < 0 || a[MINUTE] > 59 ? MINUTE :
              a[SECOND] < 0 || a[SECOND] > 59 ? SECOND :
                a[MILLISECOND] < 0 || a[MILLISECOND] > 999 ? MILLISECOND :
                  -1;

    if (getParsingFlags(config)._overflowDayOfYear && (overflow < YEAR || overflow > DATE)) {
      overflow = DATE;
    }
    if (getParsingFlags(config)._overflowWeeks && overflow === -1) {
      overflow = WEEK;
    }
    if (getParsingFlags(config)._overflowWeekday && overflow === -1) {
      overflow = WEEKDAY;
    }

    getParsingFlags(config).overflow = overflow;
  }

  return config;
}
